import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getReportTemplateDoc = (name: string, planId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    headers: {
      responseType: "blob",
    },
    url: `${URLS.REPORTING_TEMPLATE_DOWNLOAD + name}&plan_id=${planId}`,
  };
};
const downloadReport = (data: any, isRequest?: boolean): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.REPORT_DOWNLOAD,
    headers: {
      responseType: isRequest ? "json" : "blob",
    },
    body: data,
  };
};

const reportApproverList = (auditId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.REPORT_APPROVER_LIST + auditId,
  };
};

const getReportMasterData = (planId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORTING_MASTERDATA + planId}`,
  };
};
const getReportingClients = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.REPORT_CLIENT_LIST,
  };
};
const getReportClients = (userId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.REPORTING_CLIENTS + userId,
  };
};
const getReportProjects = (userId: number, planId?: number): HttpConfig => {
  const plan = planId ? `/${planId}` : "";
  const url = `${URLS.REPORTING_PROJECTS_DROPDOWN}/${userId}${plan}`;
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: url,
  };
};
const getReportingProjectDropdown = (clientId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.REPORT_PROJECT_DROPDOWN + clientId,
  };
};
const getReportingScopeDropdown = (
  userid: number,
  clientId: number,
  projectId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORTING_SCOPE_DROPDOWN}/${userid}/${clientId}/${projectId}`,
  };
};
const getReportingProjectDetail = (
  clientId: number,
  projectId: number,
  procId?: number,
  subProcId?: number
): HttpConfig => {
  const proc = procId ? `/${procId}` : "";
  const subProc = subProcId && procId ? `/${subProcId}` : "";
  const url = `${URLS.REPORT_PROJECTS}/${clientId}/${projectId}${proc}${subProc}`;
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: url,
  };
};
const getReportingProcessDropdown = (
  clientId: number,
  projectId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORT_PROJECT_PROCESS_DROPDOWN}/${clientId}/${projectId}`,
  };
};
const getReportingSubProcessDropdown = (
  clientId: number,
  projectId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORT_PROJECT_SUBPROCESS_DROPDOWN}/${clientId}/${projectId}`,
  };
};

const getObervationMasterData = (
  userId: number,
  planId: number
): HttpConfig => {
  const plan = planId ? `?plan_id=${planId}` : "";
  const url = `${URLS.REPORTING_OBSERVATION_MASTERDATA}/${userId}${plan}`;
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: url,
  };
};

const getObervationMasterDataCatalogue = (
  userId: number,
  procId: number,
  subProcId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${
      URLS.REPORTING_OBSERVATION_MASTERDATA_CATELOG + userId
    }?process=${procId}&subprocess=${subProcId}`,
  };
};

const getReportingSubProcess = (
  userId: number,
  planId: number,
  procId?: number
): HttpConfig => {
  const url = `${URLS.REPORTING_SUBPROCESS}/${userId}?plan_id=${planId}${
    procId ? `&process_id=${procId}` : ""
  }`;
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: url,
  };
};

const addBulkReport = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.ADD_OBSERVATION_BULK,
    body: data,
  };
};
const addSingleObservation = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.ADD_REPORT,
    body: data,
  };
};
const editReport = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.EDIT_REPORT + id,
    body: data,
  };
};
const deleteReport = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.DELETE_REPORT + id,
    body: data,
  };
};

const reportDownloadAction = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.REPORTING_DONLOAD_ACTION,
    body: data,
  };
};

const reportApprovedDownload = (reportId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.REPORTING_APPROVED_DOWNLOAD + reportId,
    headers: {
      responseType: "blob",
    },
    body: {},
  };
};

const reportDownloadList = (planId = 0): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORTING_DOWNLOAD_LIST}?plan_id=${planId}`,
  };
};
const getAuditees = (
  clientId: number,
  projectId: number,
  planId: number,
  typeId: number,
  locId: number,
  depId: number,
  processId: number,
  subProcId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.REPORT_GET_AUDITEES}/${clientId}/${projectId}/${planId}/${typeId}/${locId}/${depId}/${processId}/${subProcId}`,
  };
};

const reportingServices = {
  downloadReport,
  getObervationMasterData,
  getObervationMasterDataCatalogue,
  getReportClients,
  getReportMasterData,
  getReportProjects,
  reportApproverList,
  getReportTemplateDoc,
  reportDownloadAction,
  getReportingClients,
  getReportingProcessDropdown,
  getReportingProjectDetail,
  getReportingProjectDropdown,
  reportApprovedDownload,
  reportDownloadList,
  getReportingScopeDropdown,
  getReportingSubProcess,
  getReportingSubProcessDropdown,
  addBulkReport,
  editReport,
  deleteReport,
  addSingleObservation,
  getAuditees,
};

export default reportingServices;
