import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getAuditInfo = (
  userId: number,
  clientId = 0,
  projctId = 0,
  year: any = 0,
  search = "",
  size = 10,
  page = 0,
  isValid = 0
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDIT_INFO}/${userId}?client_id=${clientId}&project_id=${projctId}&year=${year}&search=${search}&size=${size}&page_number=${page}&is_approved=${isValid}`,
  };
};
const getAuditDropdowns = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.AUDIT_DROPDOWNS,
  };
};

const getProjectAudits = (clntId: number, projctId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDITPROJECTPLANS + clntId}/${projctId}`,
  };
};

const getAuditClients = (userId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDIT_CLIENTS}/${userId}`,
  };
};
const getAuditScopes = (userId: number, auditID: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDIT_INFO}/${userId}?plan_id=${auditID}`,
  };
};
const getAuditProjects = (userId: number, clntId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDIT_PROJECTS_DROPDOWN}/${userId}/${clntId}`,
  };
};
const getAuditYears = (
  userId: number,
  clntId: number,
  prjctId: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDIT_SCOPE_DROPDOWN}/${userId}/${clntId}/${prjctId}`,
  };
};
const getAuditSubProcess = (userId: number, pid: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDIT_SUBPROCESS_DROPDOWN}/${userId}/${pid}`,
  };
};
const getAuditMasterData = (
  userId: number,
  selectedProject: number
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.AUDIT_SCOPE_MASTERDATA}/${userId}?project_id=${selectedProject}`,
  };
};

const addAudit = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.ADD_AUDIT,
    body: data,
  };
};
const createDraft = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.CREATE_DRAFT,
    body: data,
  };
};
const completeDraft = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.COMPLETE_DRAFT + id,
    body: data,
  };
};

const editAudit = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.EDIT_AUDIT + id,
    body: data,
  };
};

const deleteAudit = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.DELETE_AUDIT + id,
    body: data,
  };
};

const auditServices = {
  getAuditClients,
  getAuditInfo,
  getAuditDropdowns,
  getProjectAudits,
  getAuditProjects,
  getAuditMasterData,
  getAuditScopes,
  getAuditSubProcess,
  getAuditYears,
  addAudit,
  createDraft,
  completeDraft,
  deleteAudit,
  editAudit,
};

export default auditServices;
