import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getProjectDetails = (id: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.PROJECT_DETAILS}/${id}`,
  };
};

const getPrecisionData = (name: string, code: string): HttpConfig => {
  const url = `${URLS.PRECISION_DATA}?project_name=${name}${
    code?.length > 0 ? "&project_code=" : ""
  }${code}`;
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: url,
  };
};
const getProjectMasterdata = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.PORJECT_MASTERDATA,
  };
};

const getPrecisionTasks = (jobCode: string): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.PRECISION_TASKS}/${jobCode}`,
  };
};
const getProjectList = (
  client: number = 0,
  search = "",
  size = 10,
  page = 0
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.PROJECT_LIST}?client_id=${client}&search=${search}&size=${size}&page_number=${page}`,
  };
};
const getProjectUsers = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.PROJECT_USERS,
  };
};
const getProjectClientDropdown = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.PROJECT_CLIENT_DROPDOWN,
  };
};
const getProjectProjectTypeDropdown = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.PROJECT_TYPE_DROPDOWN,
  };
};
const getProjectPartnerDropdown = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.PROJECT_PARTNER_DROPDOWN,
  };
};
const getProjectManagerDropdown = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.PROJECT_MANAGER_DROPDOWN,
  };
};
const getProjectDocument = (id: number, mappedId: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.PROJECT_DOC_DOWNLOAD}/${id}/${mappedId}`,
  };
};

const addProject = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.ADD_PROJECT,
    body: data,
  };
};
const editProject = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.EDIT_PROJECT}/${id}`,
    body: data,
  };
};
const deleteProject = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.DELETE_PROJECT + id,
    body: data,
  };
};

const projectServices = {
  getProjectClientDropdown,
  getProjectDetails,
  getPrecisionData,
  getProjectDocument,
  getProjectList,
  getPrecisionTasks,
  getProjectManagerDropdown,
  getProjectPartnerDropdown,
  getProjectProjectTypeDropdown,
  getProjectMasterdata,
  getProjectUsers,
  addProject,
  editProject,
  deleteProject,
};

export default projectServices;
