import axiosInstance from "../models/axios.instance";
import { HttpMethods, URLS } from "../models/constants";
import { HttpConfig } from "../models/types";

const getClients = (
  search: string = "",
  size: number = 10,
  page: number = 0
): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.CLIENTS}?search=${search}&size=${size}&page_number=${page}`,
  };
};

const getPrecisionClients = (clientName: string): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.PRECISION_CLIENTS}?client_name=${clientName}`,
  };
};

const getClientDetails = (id: number): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.CLIENTDETAILS + id,
  };
};

const getIndustries = (): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: URLS.INDUSTRIES,
  };
};
const getDuplicateClient = (name: string): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.GET,
    url: `${URLS.DUPLICATE_CLIENT}/${name}`,
  };
};

const addClient = (data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: URLS.ADD_CLIENT,
    body: data,
  };
};
const editClient = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.EDIT_CLIENT}/${id}`,
    body: data,
  };
};
const deleteClient = (id: number, data: any): HttpConfig => {
  return {
    instance: axiosInstance.APIInstance,
    method: HttpMethods.POST,
    url: `${URLS.DELETE_CLIENT}/${id}`,
    body: data,
  };
};

const clientServices = {
  getClients,
  getPrecisionClients,
  getClientDetails,
  getIndustries,
  getDuplicateClient,
  addClient,
  editClient,
  deleteClient,
};

export default clientServices;
